<template>
  <static-fullscreen-card @sendHeight="height = $event">
    <template v-slot:header></template>
    <template v-slot:title>
      <v-row class="ma-1">
        <v-btn-toggle v-model="period" mandatory borderless dense class="ml-1" @click="onChangePeriod">
          <v-btn value="1M" @click="onChangePeriod('1M')">
            <span class1="hidden-md-and-down">1 месяц</span>
          </v-btn>
          <v-btn value="2M" class="mx-1" @click="onChangePeriod('2M')">
            <span class1="hidden-md-and-down">2 месяца</span>
          </v-btn>
          <v-btn value="3M" class="" @click="onChangePeriod('3M')">
            <span class1="hidden-md-and-down">3 месяца</span>
          </v-btn>
        </v-btn-toggle>
      </v-row>
    </template>
    <a-loader v-if="!loaded" />
    <v-layout fill-height1>
      <Panel v-if="false" :listUsers="usersList.filter((u) => u.id > 0)" :loaded="loaded" @selectUser="users = $event" :users="users" />
      <v-row v-if="loaded" style1="margin-left: 56px" ref="row0">
        <v-col cols="12" :sm="6" class1="pa-2">
          <v-card class="ma-0" height="532">
            <v-card-title class="primary white--text py-0">Статистика</v-card-title>
            <a-table-f-data2
              ref="table"
              :dataTable="usersList"
              :model="model"
              :useQuery="false"
              :searchable="false"
              @click="clickRow($event)"
              @setCheckbox="users = $event"
            />
          </v-card>
        </v-col>
        <v-col cols="12" :sm="6" class1="pa-2">
          <Item
            :ref="`item_0`"
            :period="{ value: period, start: dateStart, end: dateEnd, days, usersList }"
            :data="data"
            :dataP="dataP"
            :user="{ id: -1, name: 'Общий' }"
            @showDetail="onShowDetail({ id: -1, name: 'Общий' }, $event)"
          />
        </v-col>
        <v-col v-for="(user, i) of users" :key="`user${i}-${user.id}`" cols="12" :sm="user.id ? 6 : 6" class1="pa-2">
          <Item
            :period="{ value: period, start: dateStart, end: dateEnd, days, usersList }"
            :data="data"
            :dataP="dataP"
            :user="user"
            @showDetail="onShowDetail(user, $event)"
          />
        </v-col>
      </v-row>
    </v-layout>
    <portal to="v-main">
      <Detail v-model="dialog" :data="selectedData" />
    </portal>
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    Detail: () => import("./detail"),
    Item: () => import("./item"),
    Panel: () => import("./panel"),
  },
  data() {
    return {
      height: null,
      selectedData: {},
      usersList: [],
      users: [],
      model: [
        { name: "checked", title: "", type: "checkbox", width: 35 },
        { name: "name", title: "", type: "string", width: 200 },
        { name: "works", title: "Всего", type: "number", width: 80 },
        { name: "done", title: "Выполнено", type: "number", width: 80 },
        { name: "wait", title: "В работе", type: "number", width: 80 },
      ],
      loaded: false,
      dialog: false,
      days: 0,
      period: "1M",
      dateStart: null,
      dateEnd: null,
      showConfig: false,
      modelConfig: [
        { name: "datePeriod", title: "Период", type: "daterange", dates: ["ds", "de"], calculated: true },
        { name: "ds", title: "Период", type: "date", hidden: true },
        { name: "de", title: "Период", type: "date", hidden: true },
      ],
      data: null,
      dataP: null,
    };
  },
  created() {
    this.$root.title = "Анализ покупок";
    this.onChangePeriod(this.period);
  },
  computed: {
    dir_user() {
      return this.$dirs.user;
    },
    hhh() {
      let res = 0;
      //if (this.loaded) res = this.$refs["row0"] && this.$refs["row0"][0].height;
      return res || 500;
    },
  },
  watch: {
    period(v) {
      //this.fetchData(v);
    },
    users(newVal, oldVal) {
      // Найти добавленные элементы
      const addedItems = newVal.filter((item) => !oldVal.includes(item));

      // Найти удаленные элементы
      const removedItems = oldVal.filter((item) => !newVal.includes(item));

      // Обработка добавленных и удаленных элементов
      if (addedItems.length) {
        console.log("Добавлены:", addedItems);
      }
      if (removedItems.length) {
        console.log("Удалены:", removedItems);
      }
    },
  },
  methods: {
    clickRow(d) {
      if (d.field.name == "checked") return;

      this.$refs.table.$refs.base.clickCheckbox(d.event, d.row, d.key);
      this.$refs.table.$refs.base.$forceUpdate();
      if (!false)
        this.users = [
          ...this.$refs.table.data.filter((el) => {
            return el?.checked;
          }),
        ];
      console.log(d.field.name, this.users);
    },
    async fetchData2(v) {
      this.loaded = false;
      this.users = [];
      let url = "/report_sql";
      //const { ds, de } = { ds: new Date().addDays(-v * 30).date, de: new Date().addDays(1).date };
      // console.log(this.dateStart, this.dateEnd);
      let params = {
        ds: this.dateStart,
        de: this.dateEnd,
      };

      let resp = await this.$axios.post(url, { name: "analizeBuying", params });
      let data = resp.data.data;
      params = {
        filters: {
          AND: [
            { date_start: { condition: ">=", value: new Date(this.dateStart).addDays(-this.days).date } },
            { date_end: { condition: "<=", value: new Date(this.dateEnd).addDays(-this.days).date } },
          ],
        },
        sort: [{ key: "id", order: "ASC" }],
      };
      params = {
        ds: new Date(this.dateStart).addDays(-this.days).date,
        de: new Date(this.dateEnd).addDays(-this.days).date,
      };
      let respP = await this.$axios.post(url, { name: "analizeBuying", params });
      let dataP = respP.data.data;
      this.doAnalysis(data, dataP);
    },

    async fetchData(v) {
      this.fetchData2();
      return;
      this.loaded = false;
      this.users = [];
      let url = "/mechti/buying/history";
      //const { ds, de } = { ds: new Date().addDays(-v * 30).date, de: new Date().addDays(1).date };
      console.log(this.dateStart, this.dateEnd);
      let params = {
        filters: {
          AND: [{ date_start: { condition: ">=", value: this.dateStart } }, { date_end: { condition: "<=", value: this.dateEnd } }],
        },
        sort: [{ key: "id", order: "ASC" }],
      };

      let resp = await this.$axios.get(url, { params });
      let data = resp.data.data;
      params = {
        filters: {
          AND: [
            { date_start: { condition: ">=", value: new Date(this.dateStart).addDays(-this.days).date } },
            { date_end: { condition: "<=", value: new Date(this.dateEnd).addDays(-this.days).date } },
          ],
        },
        sort: [{ key: "id", order: "ASC" }],
      };
      let respP = await this.$axios.get(url, { params });
      let dataP = respP.data.data;
      this.doAnalysis(data, dataP);
    },
    getUsers(data) {},

    doAnalysis(data, dataP) {
      let status = 281; //закуплен в заказе
      let users = {};
      data.map((d) => {
        const done = d.status_history.find((h) => h.value == status && [281, 203, 204].includes(d.status))?.createdon || null;
        if (!users?.[d.user_id]) users[d.user_id] = { id: d.user_id, name: d.user_name, works: 0, done: 0, wait: 0 };
        if (done) {
          if (new Date(done) > new Date(this.dateStart)) {
            users[d.user_id].done++;
            users[d.user_id].wait--;
          } else {
            users[d.user_id].works--;
            users[d.user_id].wait--;
          }
          d.date_done = done && new Date(done).date;
        }
        users[d.user_id].works++;
        users[d.user_id].wait++;
      });
      dataP.map((d) => {
        const done = d?.status_history.find((h) => h.value == status && [281, 203, 204].includes(d.status))?.createdon || null;
        if (done) {
          d.date_done = done && new Date(done).date;
        }
      });
      this.usersList = Object.values(users);
      //   this.usersList.unshift({ id: -1, name: "Общий" });
      //console.log(this.usersList);
      this.data = data;
      this.dataP = dataP;
      this.loaded = true;
      // this.users = [{ id: -1, name: "Общий" }];
    },

    async onChangePeriod(e) {
      if (e == "3M") {
        this.dateStart = new Date().addDays(-90).date;
        this.dateEnd = new Date().date;
        this.days = 90;
      }
      if (e == "2M") {
        this.dateStart = new Date().addDays(-60).date;
        this.dateEnd = new Date().date;
        this.days = 60;
      }
      if (e == "1M") {
        this.dateStart = new Date().addDays(-30).date;
        this.dateEnd = new Date().date;
        this.days = 30;
      }
      if (e == "select") {
        return;
        console.log(res);
        this.paramsApi.params.ds = new Date().date;
        this.paramsApi.params.de = new Date().addDays(30).date;
      }
      this.fetchData();

      //this.$refs.table.updateData();
    },
    onSetPeriod(e) {
      console.log(e);
      this.dateStart = new Date(e.ds).date;
      this.dateEnd = new Date(e.de).date;
      this.setTitle();
    },
    onShowDetail(user, data) {
      // Получение данных выбранного столбца
      this.selectedData = data;

      // Показ диалогового окна
      this.dialog = true;
    },
  },
};
</script>
